import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

const AmsterdamModalButton = () => {
    const { t } = useTranslation();

    return (
        <div className='flex justify-center items-center md:flex-col z-[9998]'>
            <a
                href='https://fareharbor.com/embeds/book/gameoverescaperoom/items'
                className={twMerge(
                    "bg-primary-red text-white inline-block",
                    "fixed bottom-[23px] right-[1em]",
                    "cursor-pointer z-[999] text-center",
                    "text-[16px] font-bold border-none",
                    "rounded-[2em] m-0 py-[1rem] px-[2.5rem]",
                    "hover:bg-white hover:text-primary-red md:text-[14px]",
                    "md:w-fit-content md:p-[1rem]"
                )}>
                {t("BOOK NOW")}
            </a>
        </div>
    );
};

AmsterdamModalButton.propTypes = {
    openButtonModal: PropTypes.bool,
    setOpenButtonModal: PropTypes.func,
};

export default AmsterdamModalButton;
